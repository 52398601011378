
import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { isBrowser } from '../../utils/zeotap';
import LogLink from '../components/LogLink';
import Radio from '../components/formComponents/Radio';
import { gtmRelatedFields, availableConfigs, availableFields, tooltips, tcfvOptions, envOptions, purposesList, cookieSyncOptions, optoutOptions, shouldCheckZeotapVendorConsentOptions, tooltipTextCSS, tooltipCss, hashingIdentitiesOptions, logoPositions, cdnUrl, adobeRelatedFields, rudderStackRelatedFields, persistInCookieOptions } from '../../utils/const';
import { GrCircleInformation, GrFormNext } from 'react-icons/gr';
import Select from '../components/formComponents/Select';
import "../styles/site.css";
import { Multiselect } from 'multiselect-react-dropdown';
import {clone} from 'ramda';
import { getSessionStoredJSON, getSelectedConfig, getProductCatalogOptions } from "../../utils/helpers";
import inventory from '../../providers/inventory'
import {SiteContext, ContextProviderComponent} from './../context/mainContext';

var selectedConfig = getSelectedConfig();
const categories = {sdk: 'SDK OPTIONS', consent: 'CONSENT OPTIONS', ga: 'GOOGLE ANALYTICS OPTIONS', log: 'LOG OPTIONS', idp: 'ID+ OPTIONS', adobeTarget: "Adobe target Options"};

const mapperFields = {
  [availableFields.v]: {
    label: 'Version',
    value: 'qa',
    type: 'dropdown',
    name: availableFields.v,
    category: categories.sdk,
    options: envOptions
  },
  [availableFields.mapperQueryParams]: {
    label: 'Mapper Query Param string',
    value: '',
    type: 'text',
    name: availableFields.mapperQueryParams,
    category: categories.sdk
  },
  [availableFields.disableAutoInit]: {
    label: 'Disable Auto-init',
    value: true,
    type: 'checkbox',
    name: availableFields.disableAutoInit,
    category: categories.sdk
  },

  //Consent Options

  [availableFields.useConsent]: {
    label: 'Use Consent?',
    value: false,
    type: 'checkbox',
    name: availableFields.useConsent,
    category: categories.consent
  },
  [availableFields.checkForCMP]: {
    label: 'GDPR CMP?',
    value: false,
    type: 'checkbox',
    name: availableFields.checkForCMP,
    category: categories.consent,
    hide: true
  },
  [availableFields.tcfv]: {
    label: 'Choose TCF',
    value: 'tcfv1',
    type: 'radio',
    name: availableFields.tcfv,
    category: categories.consent,
    options: tcfvOptions,
    hide: true
  },
}



const idpFields = {
  [availableFields.gtm]: {
    label: 'Enable GTM',
    value: false,
    type: 'checkbox',
    name: availableFields.gtm
  },
  [availableFields.v]: {
    label: 'Version',
    value: 'qa',
    type: 'dropdown',
    name: availableFields.v,
    category: categories.sdk,
    options: envOptions
  },
  [availableFields.user_country]: {
    label: 'Country Code',
    value: '',
    type: 'text',
    name: availableFields.user_country,
    category: categories.sdk
  },
  [availableFields.onlySecureCookie]: {
    label: 'Only Secure Cookie?',
    value: false,
    type: 'checkbox',
    name: availableFields.onlySecureCookie,
    category: categories.sdk
  },
  [availableFields.partner_dom]: {
    label: 'Partner Domain',
    value: '',
    type: 'text',
    name: availableFields.partner_dom,
    category: categories.sdk
  },

  // log options

  [availableFields.enableLogging]: {
    label: 'Logging',
    value: false,
    type: 'checkbox',
    name: availableFields.enableLogging,
    category: categories.log
  },
  [availableFields.logLevel]: {
    label: 'Loglevel',
    value: 'warn',
    type: 'dropdown',
    name: availableFields.logLevel,
    category: categories.log,
    options: [{name: 'warn'}, {name: 'trace'}, {name: 'debug'}, {name: 'info'}, {name: 'error'}],
    hide: true
  },

  // idp options

  [availableFields.allowIDP]: {
    label: 'Enable ID+ Stamping',
    value: true,
    type: 'checkbox',
    name: availableFields.allowIDP,
    category: categories.idp,
    hide: true
  },
  [availableFields.partnerId]: {
    label: 'Partner Id',
    value: 'test-org-guid',
    type: 'text',
    name: availableFields.partnerId,
    category: categories.idp
  },
  //Consent Options


  [availableFields.optOut]: {
    label: 'Opt Out?',
    value: 'null',
    type: 'radio',
    name: availableFields.optOut,
    category: categories.consent,
    options: optoutOptions
  },
  [availableFields.useConsent]: {
    label: 'Use Consent?',
    value: false,
    type: 'checkbox',
    name: availableFields.useConsent,
    category: categories.consent
  },
  [availableFields.checkForCMP]: {
    label: 'GDPR CMP?',
    value: false,
    type: 'checkbox',
    name: availableFields.checkForCMP,
    category: categories.consent,
    hide: true
  },
  [availableFields.tcfv]: {
    label: 'Choose TCF',
    value: 'tcfv1',
    type: 'radio',
    name: availableFields.tcfv,
    category: categories.consent,
    options: tcfvOptions,
    hide: true
  },
  [availableFields.roleForConsent]: {
    label: 'Role For Consent',
    value: 'publisher',
    type: 'dropdown',
    name: availableFields.roleForConsent,
    options: [{name: 'publisher'}, {name: 'vendor'}],
    category: categories.consent,
    hide: true
  },
  [availableFields.tcfPublisherConsentCategory]: {
    label: 'Tcf Publisher Consent Category',
    value: 'consents',
    type: 'dropdown',
    name: availableFields.tcfPublisherConsentCategory,
    options: [{name: 'consents'}, {name: 'legitimateInterests'}],
    category: categories.consent,
    hide: true
  },
  [availableFields.purposesForTracking]: {
    label: 'Purposes For Tracking',
    value: [{name: 'Store and/or access information on a device', id: 1}, {name: 'create a personalized ad profile', id: 3}, {name: 'Select personalised ads', id: 4}],
    type: 'multiselect',
    name: availableFields.purposesForTracking,
    category: categories.consent,
    options: purposesList,
    hide: true
  },
  [availableFields.purposesForCookieSync]: {
    label: 'Purposes For CookieSync',
    value: [{name: 'Store and/or access information on a device', id: 1}, {name: 'create a personalized ad profile', id: 3}, {name: 'Select personalised ads', id: 4}],
    type: 'multiselect',
    name: availableFields.purposesForCookieSync,
    category: categories.consent,
    options: purposesList,
    hide: true
  },
  [availableFields.purposesForIdentifying]: {
    label: 'Purposes For Identify',
    value: [{name: 'Store and/or access information on a device', id: 1}, {name: 'Apply market research to generate audience insights', id: 9}],
    type: 'multiselect',
    name: availableFields.purposesForIdentifying,
    category: categories.consent,
    options: purposesList,
    hide: true
  },
}

const collectFields = {
  [availableFields.symphony]: {
    label: 'Enable Symphony Recommendations',
    value: false,
    type: 'checkbox',
    name: availableFields.symphony
  },
  [availableFields.adobe]: {
    label: 'Enable Adobe Launch',
    value: false,
    type: 'checkbox',
    name: availableFields.adobe
  },
  [availableFields.gtm]: {
    label: 'Enable GTM',
    value: false,
    type: 'checkbox',
    name: availableFields.gtm
  },
  [availableFields.rudderstack]: {
    label: 'Enable Rudderstack',
    value: false,
    type: 'checkbox',
    name: availableFields.rudderstack
  },
  [availableFields.v]: {
    label: 'Version',
    value: 'qa',
    type: 'dropdown',
    name: availableFields.v,
    category: categories.sdk,
    options: envOptions
  },
  [availableFields.orgId]: {
    label: 'Organization ID',
    value: 0,
    type: 'number',
    name: availableFields.orgId,
    category: categories.sdk
  },
  [availableFields.wk]: {
    label: 'Write Key',
    value: 'da4875bb-a07f-42ae-abd3-19334208334b',
    type: 'text',
    name: availableFields.wk,
    category: categories.sdk
  },
  [availableFields.storageExpirationDays]: {
    label: 'Storage Expiration Days',
    value: '365',
    type: 'text',
    name: availableFields.storageExpirationDays,
    category: categories.sdk
  },
  [availableFields.domain]: {
    label: 'Domain',
    value: '',
    type: 'text',
    name: availableFields.domain,
    category: categories.sdk
  },
  [availableFields.user_country]: {
    label: 'Country Code',
    value: '',
    type: 'text',
    name: availableFields.user_country,
    category: categories.sdk
  },
  [availableFields.pvtCloudEndpoint]: {
    label: 'Private Cloud Endpoint',
    value: '',
    type: 'text',
    name: availableFields.pvtCloudEndpoint,
    category: categories.sdk
  },
  [availableFields.areIdentitiesHashed]: {
    label: 'Are Identities Hashed ?',
    value: 'null',
    type: 'radio',
    name: availableFields.areIdentitiesHashed,
    category: categories.sdk,
    options: hashingIdentitiesOptions
  },
  [availableFields.hashIdentities]: {
    label: 'hashIdentities ?',
    value: false,
    type: 'checkbox',
    name: availableFields.hashIdentities,
    category: categories.sdk,
    hide: true
  },
  [availableFields.onlySecureCookie]: {
    label: 'Only Secure Cookie?',
    value: false,
    type: 'checkbox',
    name: availableFields.onlySecureCookie,
    category: categories.sdk
  },
  [availableFields.allowCookieSync]: {
    label: 'allow CookieSync?',
    value: 'null',
    type: 'radio',
    name: availableFields.allowCookieSync,
    category: categories.sdk,
    options: cookieSyncOptions
  },
  [availableFields.persistInCookie]: {
    label: 'Persist in cookie',
    value: 'null',
    type: 'radio',
    name: availableFields.persistInCookie,
    category: categories.sdk,
    options: persistInCookieOptions
  },
    // log options
  [availableFields.enableLogging]: {
    label: 'Logging',
    value: false,
    type: 'checkbox',
    name: availableFields.enableLogging,
    category: categories.log
  },
  [availableFields.logLevel]: {
    label: 'Loglevel',
    value: 'warn',
    type: 'dropdown',
    name: availableFields.logLevel,
    category: categories.log,
    options: [{name: 'warn'}, {name: 'trace'}, {name: 'debug'}, {name: 'info'}, {name: 'error'}],
    hide: true
  },

  //idp options

  [availableFields.allowIDP]: {
    label: 'Enable ID+ Stamping',
    value: false,
    type: 'checkbox',
    name: availableFields.allowIDP,
    category: categories.idp
  },
  [availableFields.partnerId]: {
    label: 'Partner Id',
    value: 'test-org-guid',
    type: 'text',
    name: availableFields.partnerId,
    category: categories.idp,
    hide: true
  },

  //Adobe Target Options
  [availableFields.enableAdobeTarget] : {
    label: 'Enable Adobe Target',
    value: false,
    type: 'checkbox',
    name: availableFields.enableAdobeTarget,
    category: categories.adobeTarget
  },
  [availableFields.getParamsFromInteract] : {
    label: 'Get Params From Interact',
    value: false,
    type: 'checkbox',
    name: availableFields.getParamsFromInteract,
    category: categories.adobeTarget,
    hide: true
  },


  //Consent Options

  
  [availableFields.optOut]: {
    label: 'Opt Out?',
    value: 'null',
    type: 'radio',
    name: availableFields.optOut,
    category: categories.consent,
    options: optoutOptions
  },
  [availableFields.useConsent]: {
    label: 'Use Consent?',
    value: false,
    type: 'checkbox',
    name: availableFields.useConsent,
    category: categories.consent
  },
  [availableFields.checkForCMP]: {
    label: 'GDPR CMP?',
    value: false,
    type: 'checkbox',
    name: availableFields.checkForCMP,
    category: categories.consent,
    hide: true
  },
  [availableFields.tcfv]: {
    label: 'Choose TCF',
    value: 'tcfv1',
    type: 'radio',
    name: availableFields.tcfv,
    category: categories.consent,
    options: tcfvOptions,
    hide: true
  },
  [availableFields.roleForConsent]: {
    label: 'Role For Consent',
    value: 'publisher',
    type: 'dropdown',
    name: availableFields.roleForConsent,
    options: [{name: 'publisher'}, {name: 'vendor'}],
    category: categories.consent,
    hide: true
  },
  [availableFields.tcfPublisherConsentCategory]: {
    label: 'Tcf Publisher Consent Category',
    value: 'consents',
    type: 'dropdown',
    name: availableFields.tcfPublisherConsentCategory,
    options: [{name: 'consents'}, {name: 'legitimateInterests'}],
    category: categories.consent,
    hide: true
  },
  [availableFields.shouldCheckZeotapVendorConsent]: {
    label: 'shouldCheckZeotapVendorConsent?',
    value: 'null',
    type: 'radio',
    name: availableFields.shouldCheckZeotapVendorConsent,
    category: categories.consent,
    options: shouldCheckZeotapVendorConsentOptions,
    hide: true
  },
  [availableFields.purposesForTracking]: {
    label: 'Purposes For Tracking',
    value: [{name: 'Store and/or access information on a device', id: 1}, {name: 'create a personalized ad profile', id: 3}, {name: 'Select personalised ads', id: 4}],
    type: 'multiselect',
    name: availableFields.purposesForTracking,
    category: categories.consent,
    options: purposesList,
    hide: true
  },
  [availableFields.purposesForCookieSync]: {
    label: 'Purposes For CookieSync',
    value: [{name: 'Store and/or access information on a device', id: 1}, {name: 'create a personalized ad profile', id: 3}, {name: 'Select personalised ads', id: 4}],
    type: 'multiselect',
    name: availableFields.purposesForCookieSync,
    category: categories.consent,
    options: purposesList,
    hide: true
  },
  [availableFields.purposesForIdentifying]: {
    label: 'Purposes For Identify',
    value: [{name: 'Store and/or access information on a device', id: 1}, {name: 'Apply market research to generate audience insights', id: 9}],
    type: 'multiselect',
    name: availableFields.purposesForIdentifying,
    category: categories.consent,
    options: purposesList,
    hide: true
  },


  //set custom consent gtm
  [availableFields.setCustomConsentBeforeGtmInit]: {
    label: 'setCustomConsentBeforeGtmInit (use event: setCustomConsent in gtm)',
    value: false,
    type: 'checkbox',
    name: availableFields.setCustomConsentBeforeGtmInit,
    category: categories.consent,
    hide: false
  },

  // GA Options 
  [availableFields.allowGAClientId]: {
    label: 'Enable Google Analytics Client ID ',
    value: true,
    type: 'checkbox',
    name: availableFields.allowGAClientId,
    category: categories.ga
  },
  [availableFields.gaClientIdCookiePrefix]: {
    label: 'GA ClientId Cookie Prefix',
    value: '',
    type: 'text',
    name: availableFields.gaClientIdCookiePrefix,
    category: categories.ga
  },
  [availableFields.gaUserIdCookieName]: {
    label: 'Ga User Id Cookie Name',
    value: '',
    type: 'text',
    name: availableFields.gaUserIdCookieName,
    category: categories.ga
  },
  [availableFields.gaUserIdOnlyLoginEvent]: {
    label: 'GA User Id Only For Login Event',
    value: false,
    type: 'checkbox',
    name: availableFields.gaUserIdOnlyLoginEvent,
    category: categories.ga,
    hide: true
  },
}

const adobexpFields = {
  [availableFields.environment]: {
    label: 'Adobe Experience Platform Environment',
    value: 'development',
    type: 'dropdown',
    name: availableFields.environment,
    options: [{name: 'development'}, {name: 'staging'}, {name: 'production'}],
    category: categories.adobexp,
  }
}

const siteConfigFields = {
  productCatalog: {
    label: 'Product Catalogue',
    value: 'furniture',
    type: 'radio',
    name: 'productCatalog',
    options: getProductCatalogOptions(inventory)
  },
  fontFamilyUrl: {
    label: 'Font Family URL',
    value: '',
    type: 'text',
    name: 'fontFamilyUrl',
  },
  logoUrl: {
    label: 'Logo URL',
    value: cdnUrl+'images/Zeotap.png',
    type: 'text',
    name: 'logoUrl',
  },
  logoPosition: {
    label: 'Logo Position',
    value: 'left',
    type: 'radio',
    name: 'logoPosition',
    options: logoPositions
  },
  bgColor: {
    label: 'Background Color',
    value: '#ffffff',
    type: 'color',
    name: 'bgColor',
  },
  navbarColor: {
    label: 'Navigation Bar Background Color',
    value: '#ffffff',
    type: 'color',
    name: 'navbarColor',
  },
  multiPageSite: {
    label: 'Make Demosdk a Multi Page Site',
    value: false,
    type: 'radio',
    name: 'multiPageSite',
    options: [{ name: 'multiPageSite', value: true, label: 'True'}, { name: 'multiPageSite', value: false, label: 'False'}]
  }
}

const initialConfigState = {
  sdkConfig: {
    label: 'SDK Configuration',
    fields: {
      [availableConfigs.mapper]: {
        label: 'ID+ Advanced',
        value: availableConfigs.mapper,
        type: 'radio',
        name: 'sdkConfig',
        fields: {...mapperFields}
      },
      [availableConfigs.idp]: {
        label: 'ID+ Basic',
        value: availableConfigs.idp,
        type: 'radio',
        name: 'sdkConfig',
        fields: {...idpFields}
      },
      [availableConfigs.zeotap]: {
        label: 'Collect SDK',
        value: availableConfigs.zeotap,
        type: 'radio',
        name: 'sdkConfig',
        fields: {...collectFields}
      }
    },
  },
  siteConfig: {
    label: 'Site Configuration',
    fields: {...siteConfigFields}
  }
}

const getInitialState = () => {
  selectedConfig = getSelectedConfig();
  const selectedConfigState = getSessionStoredJSON("configState");
  //reset the option values of config which aren't selected if state is present in storage
  const clonedState = clone(initialConfigState); //deep clone the state
  const updatedConfig = !!selectedConfigState ? {
    ...clonedState,
    sdkConfig: {
      ...clonedState.sdkConfig,
      fields: {
        ...clonedState.sdkConfig.fields,
        [selectedConfig]: {
          ...clonedState.sdkConfig.fields[selectedConfig],
          fields: {
            ...selectedConfigState.sdkConfig.fields[selectedConfig].fields
          }
        }
      }
    },
    siteConfig: {
      ...clonedState.siteConfig,
      fields: {
        ...selectedConfigState.siteConfig.fields,
      }
    }
  } : {
    ...clonedState
  }
  return updatedConfig;
}

class Config extends React.Component {
  state = {...getInitialState(),showSDKConfig:false, showSiteConfig: false };
  clearCart = this.props.context.clearCart
  setSelectedConfig = (event) => {
    selectedConfig = event.target.value;
    this.setState({...this.state});
  }

  resetConfigIfSymphonySelectionChanged = (e) => (e.target.name === availableFields.symphony ? this.state.sdkConfig.fields[selectedConfig] = {...this.state.sdkConfig.fields[selectedConfig], fields: {...initialConfigState.sdkConfig.fields[selectedConfig].fields}} : null);

  resetConfigIfGtmSelectionChanged = (e) => (e.target.name === availableFields.gtm ? this.state.sdkConfig.fields[selectedConfig] = {...this.state.sdkConfig.fields[selectedConfig], fields: {...initialConfigState.sdkConfig.fields[selectedConfig].fields}} : null);

  resetConfigIfAdobeSelectionChanged = (e) => (e.target.name === availableFields.adobe ? this.state.sdkConfig.fields[selectedConfig] = {...this.state.sdkConfig.fields[selectedConfig], fields: {...initialConfigState.sdkConfig.fields[selectedConfig].fields}} : null);

  setValue = (field) => (field.type === 'radio' && field.value === 'null' ? undefined : (field.value === 'true' ? true : field.value === 'false' ? false : field.value))

  updateFieldValue = (e) => (this.state.sdkConfig.fields[selectedConfig].fields[e.target.name] = {...this.state.sdkConfig.fields[selectedConfig].fields[e.target.name], value: e.target.type === 'checkbox' ? e.target.checked : e.target.value})

  updateConfigAsPerGTMSelection = (e) => {
    if (e.target.name === availableFields.gtm && !!e.target.checked) {
      Object.keys(this.state.sdkConfig.fields[selectedConfig].fields).forEach((k) => {
        if (!Object.values(gtmRelatedFields).find(x => x === k)) {
          this.state.sdkConfig.fields[selectedConfig].fields[k] = {...this.state.sdkConfig.fields[selectedConfig].fields[k], hide: e.target.checked}
        }
      })
    }
  }

  updateConfigAsPerAdobeSelection = (e) => {
    console.log(e);
    if (e.target.name === availableFields.adobe && !!e.target.checked) {
      Object.keys(this.state.sdkConfig.fields[selectedConfig].fields).forEach((k) => {
        if (!Object.values(adobeRelatedFields).find(x => x === k)) {
          this.state.sdkConfig.fields[selectedConfig].fields[k] = {...this.state.sdkConfig.fields[selectedConfig].fields[k], hide: e.target.checked}
        }
      })
    }
  }

  updateConfigAsPerRudderStackSelection = (e) => {
    console.log(e);
    if (e.target.name === availableFields.rudderstack && !!e.target.checked) {
      Object.keys(this.state.sdkConfig.fields[selectedConfig].fields).forEach((k) => {
        if (!Object.values(rudderStackRelatedFields).find(x => x === k)) {
          this.state.sdkConfig.fields[selectedConfig].fields[k] = {...this.state.sdkConfig.fields[selectedConfig].fields[k], hide: e.target.checked}
        }
      })
    }
  }
  
  updateConfigforRawPii = (e) =>{
    switch(this.state.sdkConfig.fields[selectedConfig].fields[availableFields.areIdentitiesHashed].value){  
      case 'null':
        this.state.sdkConfig.fields[selectedConfig].fields[availableFields.hashIdentities] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.hashIdentities], hide: true}; 
        break;
      default:
        this.state.sdkConfig.fields[selectedConfig].fields[availableFields.hashIdentities] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.hashIdentities], hide: false};                  
  }
  }

  updateconfigforSymphony = (e) => {
    if (e.target.name === availableFields.symphony && !!e.target.checked){
        this.state.sdkConfig.fields[selectedConfig].fields[availableFields.orgId]= {...initialConfigState.sdkConfig.fields[selectedConfig].fields[availableFields.orgId], value: 188 } ;
      this.state.sdkConfig.fields[selectedConfig].fields[availableFields.wk]= {...initialConfigState.sdkConfig.fields[selectedConfig].fields[availableFields.wk], value:'4dbe6429-c9d2-47d4-8b5d-15e969687f12' } ;
      this.state.sdkConfig.fields[selectedConfig].fields[availableFields.v]= {...initialConfigState.sdkConfig.fields[selectedConfig].fields[availableFields.v], value:'demo-prod' };
      this.state.sdkConfig.fields[selectedConfig].fields[availableFields.gtm]={...initialConfigState.sdkConfig.fields[selectedConfig].fields[availableFields.gtm], hide: true};}
  }

  updateConfigAsPerConsentOptionsSelection = () => {
    const ucValue = this.state.sdkConfig.fields[selectedConfig].fields[availableFields.useConsent].value;
    const ccValue = this.state.sdkConfig.fields[selectedConfig].fields[availableFields.checkForCMP].value;
    const optoutValue = this.state.sdkConfig.fields[selectedConfig].fields[availableFields.optOut].value === 'null' ? false : true;
    const consentFields = [availableFields.roleForConsent, availableFields.tcfPublisherConsentCategory, availableFields.shouldCheckZeotapVendorConsent, availableFields.purposesForTracking, availableFields.purposesForCookieSync, availableFields.purposesForIdentifying];
    const show = !!ucValue && !!ccValue && this.state.sdkConfig.fields[selectedConfig].fields[availableFields.tcfv].value === 'tcfv2' && !optoutValue;
    this.state.sdkConfig.fields[selectedConfig].fields[availableFields.useConsent] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.useConsent], hide: optoutValue};
    this.state.sdkConfig.fields[selectedConfig].fields[availableFields.checkForCMP] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.checkForCMP], hide: !ucValue || optoutValue};
    this.state.sdkConfig.fields[selectedConfig].fields[availableFields.tcfv] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.tcfv], hide: !(ccValue && ucValue) || optoutValue };
    if (!this.state.sdkConfig.fields[selectedConfig].fields[availableFields.gtm].value) {
      consentFields.forEach(f => this.state.sdkConfig.fields[selectedConfig].fields[f] = {...this.state.sdkConfig.fields[selectedConfig].fields[f], hide: !show});
    }
  }

  updateConsentOptionsForMapperJS = () => {
    const ucValue = this.state.sdkConfig.fields[selectedConfig].fields[availableFields.useConsent].value;
    const ccValue = this.state.sdkConfig.fields[selectedConfig].fields[availableFields.checkForCMP].value;
    this.state.sdkConfig.fields[selectedConfig].fields[availableFields.checkForCMP] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.checkForCMP], hide: !ucValue};
    this.state.sdkConfig.fields[selectedConfig].fields[availableFields.tcfv] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.tcfv], hide: !(ccValue && ucValue) };
  }

  showOption = (e) => {
    switch (e.target.name) {
      case availableFields.enableLogging:
        this.state.sdkConfig.fields[selectedConfig].fields[availableFields.logLevel] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.logLevel], hide: !e.target.checked};
        break;
      case availableFields.allowIDP:
        this.state.sdkConfig.fields[selectedConfig].fields[availableFields.partnerId] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.partnerId], hide: !e.target.checked};
        break;
      case availableFields.allowGAClientId:
        this.state.sdkConfig.fields[selectedConfig].fields[availableFields.gaClientIdCookiePrefix] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.gaClientIdCookiePrefix], hide: !e.target.checked};
        break;
      case availableFields.gaUserIdCookieName:
        this.state.sdkConfig.fields[selectedConfig].fields[availableFields.gaUserIdOnlyLoginEvent] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.gaUserIdOnlyLoginEvent], hide: !e.target.value};
        break;
      case availableFields.enableAdobeTarget:
        this.state.sdkConfig.fields[selectedConfig].fields[availableFields.getParamsFromInteract] = {...this.state.sdkConfig.fields[selectedConfig].fields[availableFields.getParamsFromInteract], hide: !e.target.checked};
        break;
      default:
    }
  } 
  onChangeSiteConfig = (e) => {
    this.state.siteConfig.fields[e.target.name] = {...this.state.siteConfig.fields[e.target.name], value: e.target.type === 'checkbox' ? e.target.checked : e.target.value}
    this.setState({...this.state});
  }
  onSelectSiteConfig = (selectedList, selectedItem, fieldName) => {
    this.state.siteConfig.fields[fieldName] = {...this.state.siteConfig.fields[fieldName], value: selectedList};
    this.setState({...this.state});
  }

  onChange = (e) => {
    this.resetConfigIfSymphonySelectionChanged(e);
    this.resetConfigIfGtmSelectionChanged(e);
    this.resetConfigIfAdobeSelectionChanged(e);
    this.updateFieldValue(e);
    if(selectedConfig === availableConfigs.zeotap) {
      this.updateConfigforRawPii(e);
      this.updateConfigAsPerAdobeSelection(e);
    }
    if (selectedConfig === availableConfigs.zeotap || selectedConfig === availableConfigs.idp) {
      this.updateconfigforSymphony(e);
      this.updateConfigAsPerGTMSelection(e);
      this.updateConfigAsPerRudderStackSelection(e);
      this.showOption(e);
      this.updateConfigAsPerConsentOptionsSelection();
    } else if (selectedConfig === availableConfigs.mapper) {
      this.updateConsentOptionsForMapperJS();
    }
    this.setState({...this.state});
  }

  onSelect = (selectedList, selectedItem, fieldName) => {
    this.state.sdkConfig.fields[selectedConfig].fields[fieldName] = {...this.state.sdkConfig.fields[selectedConfig].fields[fieldName], value: selectedList};
    this.setState({...this.state});
  }

  setSessionStoredString = (s, key) =>{
    if(isBrowser() && window.sessionStorage){
        return window.sessionStorage.setItem(key,s); 
    }
    return '';
  }

  setShowSDKConfig = () => {
    this.setState({...this.state,showSDKConfig: !this.state.showSDKConfig});
  }
  setShowSiteConfig = () => {
    this.setState({...this.state,showSiteConfig: !this.state.showSiteConfig});
  }

  addConfigDetails = () => {
    this.clearCart();
    const sdkConfigDetails = Object.values(this.state.sdkConfig.fields[selectedConfig].fields).reduce((acc, curr) => ({...acc, ...!curr.hide && {[curr.name]: this.setValue(curr)}}), {})
    this.setSessionStoredString(JSON.stringify({selectedConfig, sdkConfigDetails}), "configString");
    this.setSessionStoredString(JSON.stringify(this.state), "configState");
    if(isBrowser())
    window.location.href = '/home/';
  }

//|| (i>0 && arr[i-1].category === arr[i].category && arr[i-1].hide && !f.hide) 
  render() {
    const checkboxClass = "cursor-pointer w-5 h-5";
    const textBoxClass = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline";
    const colorBoxClass = "shadow appearance-none border rounded w-10 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline";
      return (
        this.state ?
        <>
        {isBrowser() && window.location.pathname !== '/' && <LogLink />}
        <div className="pt-10">
          <h3>Config Details</h3>
          <div className="flex flex-1 justify-center">
            <div className="w-5/6 max-w-4xl">
              <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="flex items-center"><span onClick={this.setShowSiteConfig} className="toggle"><GrFormNext /></span><label className="block text-gray-700 text-lg font-bold" htmlFor="siteConfig">{this.state.siteConfig.label}</label></div>
                {(
                <div className={this.state.showSiteConfig? 'hidden': "grid grid-cols-2 col-gap-4"}>
                  <div className="pl-8">
                    {
                      Object.values(this.state.siteConfig.fields).map((f,i, arr) => (
                      <div key={i}>
                        {/* category heading */}
                        {(i === 0 || (i>0 && arr[i-1].category !== arr[i].category && !f.hide)) ? (<label className="block underline text-lg font-bold my-6">{f.category}</label>) : null}

                        {/* options */}
                        <div className={!!f.hide ? 'hidden': (f.type === 'checkbox' ? "flex col-gap-2 items-center my-2" : "my-2")}>
                          {f.type === 'checkbox' ? (<input aria-label={f.name} onChange={this.onChangeSiteConfig} value={f.value} name={f.name} checked={f.value}
                            className={f.disabled ? checkboxClass + ' pointer-events-none bg-gray-200': checkboxClass} id={f.name} type='checkbox' />) : null}
                          <div className="flex items-center my-2">
                            <label className="block text-gray-700 text-sm font-bold" htmlFor={f.name}>
                              <span>{f.label}</span>
                            </label>
                            {!!tooltips[f.name] ? (<div className={"tooltip "+ tooltipCss}><GrCircleInformation />
                                <span className={"tooltiptext "+ tooltipTextCSS}>{tooltips[f.name]}</span>
                            </div>) : null}
                          </div>
                          {f.type === 'text' || f.type === 'number' ? <input aria-label={f.name} onChange={this.onChangeSiteConfig} value={f.value} name={f.name}
                            className={f.disabled ? textBoxClass + ' pointer-events-none bg-gray-200': textBoxClass} id={f.name} type={f.type} /> : null}
                          {f.type === 'color' ? <input aria-label={f.name} onChange={this.onChangeSiteConfig} value={f.value} name={f.name}
                            className={f.disabled ? colorBoxClass + ' pointer-events-none bg-gray-200': colorBoxClass} id={f.name} type={f.type} /> : null}
                          {f.type === 'radio' ? <Radio setOptions={this.onChangeSiteConfig} options={f.options} value={f.value} customClass="mr-4 text-sm" customOptionParentCSS={f.name==='productCatalog' ? 'grid-custom': "flex"}/> : null}
                          {f.type === 'dropdown' ? <Select setOptions={this.onChangeSiteConfig} defaultOption='' options={f.options} name={f.name} value={f.value} disabled={f.disabled}/> : null}
                          {isBrowser() && f.type === 'multiselect' ? <Multiselect options={f.options} showCheckbox={true} displayValue="name" selectedValues={f.value} onSelect={(l,s) => this.onSelectSiteConfig(l,s,f.name)} onRemove={(l,s) => this.onSelectSiteConfig(l,s,f.name)} closeOnSelect={false} disable={f.disabled} />: null}
                        </div>
                      </div>
                      ))
                    }
                  </div>
                </div>
                )}
                <div className="flex items-center"><span onClick={this.setShowSDKConfig} className="toggle"><GrFormNext /></span><label className="block text-gray-700 text-lg font-bold" htmlFor="sdkConfig">{this.state.sdkConfig.label}</label></div>
                {
                (<div className={this.state.showSDKConfig? 'hidden': "grid grid-cols-2 col-gap-4"}>
                  <div className="pl-8 pt-8">
                    <Radio setOptions={this.setSelectedConfig} options={Object.values(this.state.sdkConfig.fields)} value={selectedConfig} customClass="mb-10"/> 
                  </div>
                  <div>
                    {
                      Object.values(this.state.sdkConfig.fields[selectedConfig].fields).map((f,i, arr) => (
                      <div key={i}>
                        {/* category heading */}
                        {(i === 0 || (i>0 && arr[i-1].category !== arr[i].category && !f.hide)) ? (<label className="block underline text-lg font-bold my-6">{f.category}</label>) : null}

                        {/* gtm config info */}
                        {
                          ((!!this.state.sdkConfig.fields[selectedConfig].fields[availableFields.gtm] && this.state.sdkConfig.fields[selectedConfig].fields[availableFields.gtm].value)
                           || (!!this.state.sdkConfig.fields[selectedConfig].fields[availableFields.adobe] && this.state.sdkConfig.fields[selectedConfig].fields[availableFields.adobe].value)) 
                           && i===1 ? 
                          (<div className="text-lg font-bold mb-2">Set the below Application config similar to the TAG config.</div>) : null
                        }

                        {/* options */}
                        <div className={!!f.hide ? 'hidden': (f.type === 'checkbox' ? "flex col-gap-2 items-center my-2" : "my-2")}>
                          {f.type === 'checkbox' ? (<input aria-label={f.name} onChange={this.onChange} value={f.value} name={f.name} checked={f.value}
                            className={f.disabled ? checkboxClass + ' pointer-events-none bg-gray-200': checkboxClass} id={f.name} type='checkbox' />) : null}
                          <div className="flex items-center my-2">
                            <label className="block text-gray-700 text-sm font-bold" htmlFor={f.name}>
                              <span>{f.label}</span>
                            </label>
                            {!!tooltips[f.name] ? (<div className={"tooltip "+ tooltipCss}><GrCircleInformation />
                                <span className={"tooltiptext "+ tooltipTextCSS}>{tooltips[f.name]}</span>
                            </div>) : null}
                          </div>
                          {f.type === 'text' || f.type === 'number' ? <input aria-label={f.name} onChange={this.onChange} value={f.value} name={f.name}
                            className={f.disabled ? textBoxClass + ' pointer-events-none bg-gray-200': textBoxClass} id={f.name} type={f.type} /> : null}
                          {f.type === 'color' ? <input aria-label={f.name} onChange={this.onChangeSiteConfig} value={f.value} name={f.name}
                            className={f.disabled ? colorBoxClass + ' pointer-events-none bg-gray-200': colorBoxClass} id={f.name} type={f.type} /> : null}
                          {f.type === 'radio' ? <Radio setOptions={this.onChange} options={f.options} value={f.value} customClass="mr-4 text-sm" customOptionParentCSS={f.name==='productCatalog' ? 'grid-custom': "flex"}/> : null}
                          {f.type === 'dropdown' ? <Select setOptions={this.onChange} defaultOption='' options={f.options} name={f.name} value={f.value} disabled={f.disabled}/> : null}
                          {isBrowser() && f.type === 'multiselect' ? <Multiselect options={f.options} showCheckbox={true} displayValue="name" selectedValues={f.value} onSelect={(l,s) => this.onSelect(l,s,f.name)} onRemove={(l,s) => this.onSelect(l,s,f.name)} closeOnSelect={false} disable={f.disabled} />: null}
                        </div>
                      </div>
                      ))
                    }
                  </div>
                </div>)
                }

                <div className="flex items-center justify-between mt-6">
                  <button onClick={this.addConfigDetails} className="bg-secondary hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Update Config
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        </>
        : ''
      )
    }
}

function ConfigWithContext(props) {
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {
          context => <Config {...props} context={context} />
        }
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}

export default ConfigWithContext
