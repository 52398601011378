import React from 'react'

export default class Select extends React.Component {
    constructor(props){
        super(props);
        this.state={}
    }
    updateChildState(event) {
        const { name, value } = event.target;
        this.setState({
            [name] : value
            });
        this.props.setOptions(event)
    }
    render() {
        const dropdownCss = "p-2 shadow border rounded w-1/2 mb-3 bg-white";
        return (
                <select onChange={(e)=>this.updateChildState(e)} name={this.props.name} key={this.props.name} value={this.props.value} 
                className={this.props.disabled ? dropdownCss+ " pointer-events-none bg-gray-200" : dropdownCss}>
                    {
                        this.props.options.map((o, i) =>(
                            <option key={i} value={o.name}>{o.name}</option>    
                        ))
                    }
                </select>
        )
    }
}